/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const sharedUpdatePreferences = /* GraphQL */ `
  mutation SharedUpdatePreferences(
    $visualDensity: String
    $visualMode: String
  ) {
    sharedUpdatePreferences(
      visualDensity: $visualDensity
      visualMode: $visualMode
    ) {
      visualDensity
      visualMode
    }
  }
`;
export const translationCreateJob = /* GraphQL */ `
  mutation TranslationCreateJob(
    $input: translation_createJob_input
    $limit: Int
    $nextToken: String
  ) {
    translationCreateJob(input: $input, limit: $limit, nextToken: $nextToken) {
      contentType
      createdAt
      id
      jobIdentity
      jobName
      jobOwner
      jobStatus
      languageSource
      languageTargets
      sourceKey
      sourceStatus
      translateCallback
      translateKey
      translateStatus
    }
  }
`;
